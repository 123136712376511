<template>
    <section class="cart-section section-b-space">
        <div class="container">
            <div class="row">
                
                <div class="col-sm-12">
                  <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                        <tr class="table-head">
                            <th width="600" align="left">Nom et/ou Description</th>
                            <th width="170">Type</th>
                            <th width="70">Telecharger</th>
                        </tr>
                        </thead>
                        <tr v-for="(item,index) in documentationMedias" :key="index">
                            <td align="left">
                                {{ item.file_name }}
                            </td>
                            <td>
                                {{ item.extension }}
                            </td>
                            <td>
                                <a :href="item.original_url" target="_blank" :download="item.name">Download</a>
                            </td>
                        </tr>
                    </table>
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import bannerMixins from "../../../../mixins/bannerMixins";

  export default {
    name: "documents",

    mixins:[bannerMixins],

    props:{
    },

    data(){
      return{
        documentList:[]
      }
    },

    mounted() {
      this.getMedias('documentationMedia')
    }

  };
</script>

<style scoped>

</style>